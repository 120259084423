import type { JamBaseRadioOption } from '../components/JamBaseRadio.vue';
import type { JamBaseSelectOption } from '../components/JamBaseSelect.vue';
import type { OptionItem } from '../types/general';
import type { JamFormStepDesignListIconStyleOption } from '../types/jamForm';
import type { JobAdStatus } from '../types/jobAd';
import type { JobAdProductItem } from '../types/jobAdProduct';
import type { JobActionItem } from '../types/jobAdQa';
import type { JobListInternalConfirmationModalType } from '../types/jobList';

export const JOB_PUSH_STATUS_OPTIONS: OptionItem[] = [
    { label: 'Aktiv', value: 'ACTIVE' },
    { label: 'Abgelaufen', value: 'EXPIRED' },
    { label: 'Geplant', value: 'SCHEDULED' },
];

export const LANGUAGE_OPTIONS = [
    {
        icon: 'flag-at',
        label: 'Deutsch',
        value: 'DE',
    },
    {
        icon: 'flag-uk',
        label: 'Englisch',
        value: 'EN',
    },
];

export const SKILL_TYPE_OPTIONS: Record<
    'mustHave' | 'niceToHave',
    { label: string; value: boolean }
> = {
    mustHave: { label: 'Erforderlich', value: true },
    niceToHave: { label: 'Optional', value: false },
};

export const WORKDAYS_IN_WEEK_OPTIONS: OptionItem[] = [
    {
        label: 'Mo',
        value: 'MON',
    },
    {
        label: 'Di',
        value: 'TUE',
    },
    {
        label: 'Mi',
        value: 'WED',
    },
    {
        label: 'Do',
        value: 'THU',
    },
    {
        label: 'Fr',
        value: 'FRI',
    },
    {
        label: 'Sa',
        value: 'SAT',
    },
    {
        label: 'So',
        value: 'SUN',
    },
];

export const SALARY_CURRENCY_OPTIONS: OptionItem[] = [
    {
        label: 'Euro',
        value: 'EUR',
    },
    {
        label: 'Schweizer Franken',
        value: 'CHF',
    },
    {
        label: 'Britisches Pfund',
        value: 'GBP',
    },
    {
        label: 'Tschechische Kronen',
        value: 'CZK',
    },
    {
        label: 'Ungarischer Forint',
        value: 'HUF',
    },
    {
        label: 'US-Dollar',
        value: 'USD',
    },
];

export const SALARY_UNIT_OPTIONS: OptionItem[] = [
    {
        label: 'Jährlich',
        value: 'YEARLY',
    },
    {
        label: 'Monatlich',
        value: 'MONTHLY',
    },
    {
        label: 'Täglich',
        value: 'DAILY',
    },
    {
        label: 'Stündlich',
        value: 'HOURLY',
    },
];

export const SALARY_TYPE_OPTIONS: OptionItem[] = [
    {
        icon: 'euro',
        info: 'Geben Sie das feste Gehalt für diese Stelle an, ohne variable Anteile wie Boni oder Provisionen.',
        label: 'Fixgehalt',
        labelAdditional: 'Bruttofixgehalt',
        placeholder: 'Fixgehalt eingeben',
        value: 'FIXED-SALARY',
    },
    {
        icon: 'arrow-min',
        info: 'Geben Sie das Mindest-Gehalt für diese Stelle an, ohne variable Anteile wie Boni oder Provisionen.',
        label: 'Mindestgehalt',
        labelAdditional: 'Mindestgehalt',
        placeholder: 'Mindestgehalt eingeben',
        value: 'FROM-SALARY',
    },
    {
        icon: 'arrows-range',
        info: 'Geben Sie den Bereich des möglichen Gehalts an, von einem Mindest- bis zu einem Höchstbetrag, ohne variable Anteile wie Boni oder Provisionen.',
        label: 'Gehaltsspanne',
        labelAdditional: 'Bruttogehaltsspanne',
        placeholder: 'Bruttogehaltsspanne eingeben',
        value: 'RANGED-SALARY',
    },
];

export const CONTENT_SALUTATION_OPTIONS: OptionItem[] = [
    {
        info: 'Persönliche Anrede',
        label: 'Per Du',
        value: 'INFORMAL',
    },
    {
        info: 'Höflichkeitsform',
        label: 'Per Sie',
        value: 'FORMAL',
    },
    {
        label: 'Neutrale Anrede',
        value: 'NEUTRAL',
    },
];

export const WORKPLACE_TYPES: OptionItem[] = [
    {
        info: 'Beschäftigung nur vor Ort',
        label: 'On-site',
        value: 'ON-SITE',
    },
    {
        info: 'Beschäftigung vor Ort mit Homeoffice-Möglichkeit',
        label: 'Hybrid',
        value: 'HYBRID',
    },
    {
        info: 'Nicht vor Ort, ausschließlich Remote',
        label: 'Remote',
        value: 'REMOTE',
    },
];

export const EMPLOYMENT_TYPES_OPTIONS: OptionItem[] = [
    { label: 'Festanstellung', value: 'FIXED-EMPLOYMENT' },
    { label: 'Praktika', value: 'INTERNSHIP' },
    { label: 'Lehre', value: 'APPRENTICESHIP' },
    { label: 'Diplomarbeit', value: 'THESIS' },
    { label: 'Freelancer / Projektarbeit', value: 'FREELANCE' },
    { label: 'Studentische Mitarbeit', value: 'STUDENT-JOB' },
];

export const WORK_TIME_OPTIONS: OptionItem[] = [
    { icon: 'clock', label: 'Vollzeit', value: 'FULL-TIME' },
    { icon: 'clock-halftime', label: 'Teilzeit', value: 'PART-TIME' },
    { icon: 'clock-quarter', label: 'Geringfügig', value: 'MINOR-EMPLOYMENT' },
];

export const JOB_LEVEL_OPTIONS: OptionItem[] = [
    { label: 'Berufseinstieg', value: 'CAREER-ENTRY' },
    { label: 'Berufserfahrung', value: 'PROFESSIONAL-EXPERIENCE' },
    {
        label: 'Projekt-, Bereichsleitung',
        value: 'PROJECT-AND-DEPARTMENT-MANAGEMENT',
    },
    { label: 'Unternehmensführung', value: 'COMPANY-MANAGEMENT' },
    { label: 'Selbständigkeit', value: 'SELF-EMPLOYMENT' },
];

export const JOB_FIELD_OPTIONS: OptionItem[] = [
    {
        label: 'Assistenz, Verwaltung',
        value: 'ASSISTANCE-ADMINISTRATION',
    },
    {
        label: 'Beratung, Consulting',
        value: 'CONSULTING',
    },
    {
        label: 'Coaching, Training',
        value: 'COACHING-TRAINING',
    },
    {
        label: 'Einkauf, Logistik',
        value: 'PURCHASE-LOGISTICS',
    },
    {
        label: 'Handel',
        value: 'COMMERCE',
    },
    {
        label: 'Handwerk, Gewerbe',
        value: 'CRAFTS-TRADE',
    },
    {
        label: 'Finanzen, Bankwesen',
        value: 'FINANCE',
    },
    {
        label: 'Führung, Management',
        value: 'MANAGEMENT',
    },
    {
        label: 'Gastronomie, Tourismus',
        value: 'GASTRONOMY-TOURISM',
    },
    {
        label: 'Grafik, Design',
        value: 'GRAPHICS-DESIGN',
    },
    {
        label: 'IT, EDV',
        value: 'IT-COMPUTING',
    },
    {
        label: 'Marketing, PR',
        value: 'MARKETING',
    },
    {
        label: 'Personalwesen',
        value: 'HUMAN-RESOURCES',
    },
    {
        label: 'Pharma, Gesundheit, Soziales',
        value: 'PHARMA-HEALTH-SOCIAL',
    },
    {
        label: 'Produktion, Handwerk',
        value: 'PRODUCTION-CRAFTSMANSHIP',
    },
    {
        label: 'Rechnungswesen, Controlling',
        value: 'ACCOUNTING-CONTROLLING',
    },
    {
        label: 'Rechtswesen',
        value: 'LEGAL',
    },
    {
        label: 'Sachbearbeitung',
        value: 'ADMINISTRATION',
    },
    {
        label: 'Sonstige Berufe',
        value: 'OTHER-JOBS',
    },
    {
        label: 'Technik, Ingenieurwesen',
        value: 'TECHNOLOGY-ENGINEERING',
    },
    {
        label: 'Verkauf, Kundenbetreuung',
        value: 'SALES-CUSTOMER-SERVICE',
    },
    {
        label: 'Wissenschaft, Forschung',
        value: 'SCIENCE-RESEARCH',
    },
];

export const JOB_ENTRY_DATE_OPTIONS: JamBaseRadioOption[] = [
    {
        label: 'Jederzeit',
        value: false,
    },
    {
        label: 'Zeitpunkt festlegen',
        value: true,
    },
];

export const LANGUAGE_SKILL_OPTIONS: JamBaseSelectOption[] = [
    { label: 'Englisch', value: 'en' },
    { label: 'Deutsch', value: 'de' },
    { label: 'Italienisch', value: 'it' },
    { label: 'Ungarisch', value: 'hu' },
    { label: 'Tschechisch', value: 'cs' },
    { label: 'Slowakisch', value: 'sk' },
    { label: 'Polnisch', value: 'pl' },
    { label: 'Französisch', value: 'fr' },
    { label: 'Portugiesisch', value: 'pt' },
    { label: 'Spanisch', value: 'es' },
    { label: 'Russisch', value: 'ru' },
    { label: 'Kroatisch', value: 'hr' },
    { label: 'Serbisch', value: 'sr' },
    { label: 'Bosnisch', value: 'bs' },
    { label: 'Rumänisch', value: 'ro' },
    { label: 'Türkisch', value: 'tr' },
    { label: 'Niederländisch', value: 'nl' },
    { label: 'Arabisch', value: 'ar' },
    { label: 'Griechisch', value: 'el' },
    { label: 'Ukrainisch', value: 'uk' },
    { label: 'Hebräisch', value: 'he' },
    { label: 'Persisch', value: 'fa' },
    { label: 'Chinesisch', value: 'zh' },
    { label: 'Japanisch', value: 'ja' },
    { label: 'Koreanisch', value: 'ko' },
    { label: 'Schwedisch', value: 'sv' },
    { label: 'Finnisch', value: 'fi' },
    { label: 'Dänisch', value: 'da' },
    { label: 'Norwegisch', value: 'no' },
    { label: 'Hindi', value: 'hi' },
    { label: 'Bengalisch', value: 'bn' },
    { label: 'Panjabi', value: 'pa' },
    { label: 'Urdu', value: 'ur' },
    { label: 'Vietnamesisch', value: 'vi' },
    { label: 'Thailändisch', value: 'th' },
    { label: 'Malaiisch', value: 'ms' },
    { label: 'Indonesisch', value: 'id' },
    { label: 'Tamil', value: 'ta' },
    { label: 'Telugu', value: 'te' },
    { label: 'Kannada', value: 'kn' },
    { label: 'Malayalam', value: 'ml' },
    { label: 'Marathi', value: 'mr' },
    { label: 'Gujarati', value: 'gu' },
    { label: 'Amharisch', value: 'am' },
    { label: 'Swahili', value: 'sw' },
    { label: 'Yoruba', value: 'yo' },
    { label: 'Igbo', value: 'ig' },
    { label: 'Hausa', value: 'ha' },
    { label: 'Afrikaans', value: 'af' },
    { label: 'Albanisch', value: 'sq' },
    { label: 'Armenisch', value: 'hy' },
    { label: 'Aserbaidschanisch', value: 'az' },
    { label: 'Baskisch', value: 'eu' },
    { label: 'Weißrussisch', value: 'be' },
    { label: 'Bulgarisch', value: 'bg' },
    { label: 'Katalanisch', value: 'ca' },
    { label: 'Esperanto', value: 'eo' },
    { label: 'Estnisch', value: 'et' },
    { label: 'Georgisch', value: 'ka' },
    { label: 'Irisch', value: 'ga' },
    { label: 'Isländisch', value: 'is' },
    { label: 'Kasachisch', value: 'kk' },
    { label: 'Khmer', value: 'km' },
    { label: 'Kirgisisch', value: 'ky' },
    { label: 'Luxemburgisch', value: 'lb' },
    { label: 'Litauisch', value: 'lt' },
    { label: 'Lettisch', value: 'lv' },
    { label: 'Mazedonisch', value: 'mk' },
    { label: 'Mongolisch', value: 'mn' },
    { label: 'Nepalesisch', value: 'ne' },
    { label: 'Singhalesisch', value: 'si' },
    { label: 'Slowenisch', value: 'sl' },
    { label: 'Usbekisch', value: 'uz' },
    { label: 'Xhosa', value: 'xh' },
    { label: 'Zulu', value: 'zu' },
    { label: 'Kurdisch', value: 'ku' },
    { label: 'Paschtunisch/Afghanisch', value: 'ps' },
];

export const LANGUAGE_LEVEL_OPTIONS: OptionItem[] = [
    {
        label: 'A1 (Grundkenntnisse)',
        value: 'A1',
    },
    {
        label: 'A2 (erweiterte Grundkenntnisse)',
        value: 'A2',
    },
    {
        label: 'B1 (gute Sprachkenntnisse)',
        value: 'B1',
    },
    {
        label: 'B2 (sehr gute / fließende Sprachkenntnisse)',
        value: 'B2',
    },
    {
        label: 'C1 (verhandlungssichere Sprachkenntnisse)',
        value: 'C1',
    },
    {
        label: 'C2 ((fast) muttersprachliches Niveau)',
        value: 'C2',
    },
];

export const APPLICATION_STANDARD_DOCUMENT_OPTIONS: OptionItem[] = [
    {
        label: 'Lebenslauf',
        value: 'CV',
    },
    {
        label: 'Motivationsschreiben',
        value: 'MOTIVATION-LETTER',
    },
    {
        label: 'Führerschein',
        value: 'DRIVING-LICENCE',
    },
    {
        label: 'Polizeiliches Führungszeugnis',
        value: 'CRIMINAL-RECORD-CERTIFICATE',
    },
];

export const PRODUCT_TYPES: JobAdProductItem[] = <const>[
    {
        facts: {
            ai: true,
            companyColor: false,
            media: false,
            push: false,
            pushDays: '20',
            range: 'Standard',
            rangeInfo:
                'Stelleninserat-Platzierung auf karriere.at & relevanten Partnerseiten, z.B. jobradius.at, itstellen.at, techtalents.at, financejobs.at, salescareer.at uvm.',
            runtime: '60 Tage',
        },
        id: '100000000',
        label: 'Basic',
        value: 'BASIC',
    },
    {
        facts: {
            ai: true,
            companyColor: true,
            maxImageVideoGallery: 3,
            maxTitleImages: 1,
            media: true,
            push: false,
            pushDays: '10',
            range: 'Erweiterte',
            rangeInfo:
                'Stelleninserat-Platzierung auf karriere.at & relevanten Partnerseiten und erweiterte Reichweite in Social Media Netzwerken, Display Ads & Metajobbörsen.',
            rangeRate: 27,
            runtime: '60 Tage (Plus) oder 30 Tage (Plus Starter)',
            variant: 'lightGray',
        },
        id: '100000001',
        label: 'Plus',
        value: 'PLUS',
    },
    {
        facts: {
            ai: true,
            companyColor: true,
            maxImageVideoGallery: 3,
            maxTitleImages: 1,
            media: true,
            push: true,
            pushDays: '10',
            range: 'Maximale',
            rangeInfo:
                'Stelleninserat-Platzierung auf karriere.at & relevanten Partnerseiten und erweitereten Reichweite in Social Media Netzwerken, Display Ads, Metajobbörsen & Premium Publishern.',
            rangeRate: 49,
            runtime: '60 Tage',
            variant: 'darkGray',
        },
        id: '100000002',
        label: 'Premium',
        value: 'PREMIUM',
    },
];

export const GENERAL_VALIDATION_MESSAGES = {
    dateConstraintFuture: 'Das Datum muss in der Zukunft liegen',
    dateInvalid: 'Datum muss gültig sein',
};

export const PUBLISH_DATE_OPTIONS: OptionItem[] = [
    {
        action: 'activate',
        label: 'Ab sofort',
        value: true,
    },
    {
        action: 'schedule',
        label: 'Zeitpunkt festlegen',
        value: false,
    },
];

export const OVERTIME_HANDLING_OPTIONS: OptionItem[] = [
    {
        info: '',
        label: 'Nein, keine Angabe',
        value: 'NO-INFORMATION',
    },
    {
        info: 'Stunden können flexibel abgebaut werden',
        label: 'Zeitausgleich',
        value: 'FLEXIBLE-HOURS',
    },
    {
        info: 'Überstunden werden ausbezahlt',
        label: 'Auszahlung von Überstunden',
        value: 'PAID-OVER-HOURS',
    },
    {
        info: 'Überstunden können flexibel abgebaut oder ausbezahlt werden',
        label: 'Zeitausgleich und Auszahlung von Überstunden',
        value: 'FLEXIBLE-AND-PAID-OVER-HOURS',
    },
    {
        info: 'Eine gewisse Anzahl an Überstunden sind bereits im Gehalt inbegriffen',
        label: 'Überstundenpauschale',
        value: 'OVERHOUR-RATE',
    },
    {
        info: 'Überstunden werden nicht ausbezahlt',
        label: 'All-In',
        value: 'ALL-IN',
    },
];

export const JOB_AD_HISTORY_ACTIONS_OPTIONS: OptionItem[] = [
    { info: 'Job created', label: 'Entwurf erstellt', value: 'CREATION' },
    { info: 'Job scheduled', label: 'Inserat geplant', value: 'SCHEDULING' },
    { info: 'Job activated', label: 'Inserat aktiviert', value: 'ACTIVATION' },
    {
        info: 'Job deactivated manually',
        label: 'Inserat manuell deaktiviert',
        value: 'DEACTIVATION',
    },
    {
        info: 'Job reactivated manually',
        label: 'Inserat manuell reaktiviert',
        value: 'REACTIVATION',
    },
    { info: 'Job cancelled', label: 'Inserat storniert', value: '' }, // will be implemented with later ticket
    {
        info: 'Job prolonged',
        label: 'Inserat verlängert',
        value: 'PROLONGATION',
    },
    {
        info: 'Goodwill prolongation',
        label: 'Kulanz-Verlängerung (14 Tage)',
        value: 'FREE-PROLONGATION',
    },
    {
        info: 'Job expired',
        label: 'Inserat deaktiviert (Laufzeitende)',
        value: 'EXPIRE',
    },
    { info: 'Job locked', label: 'Inserat gesperrt', value: 'LOCK' },
    { info: 'Job unlocked', label: 'Inserat entsperrt', value: 'UNLOCK' },
    { info: 'Job deleted', label: 'Entwurf archiviert', value: 'DELETE' },
    {
        info: 'Job restored',
        label: 'Entwurf wiederhergestellt',
        value: 'RESTORE',
    },
    {
        info: 'Playout channel',
        label: 'Playout channel',
        value: 'PLAYOUT-CHANNEL',
    },
];

export const MOCK_USERS = [
    {
        firstName: 'Sarah',
        id: '156',
        lastName: 'Binder',
    },
    {
        firstName: 'Julia',
        id: '231',
        lastName: 'Koller',
    },
    {
        firstName: 'Julia',
        id: '249',
        lastName: 'Taschler',
    },
    {
        firstName: 'Matthias',
        id: '357',
        lastName: 'Weichselbaumer',
    },
    {
        firstName: 'Natalie',
        id: '384',
        lastName: 'Rathbauer',
    },
    {
        firstName: 'Laura',
        id: '425',
        lastName: 'Praher',
    },
    {
        firstName: 'Chira',
        id: '505',
        lastName: 'Amin',
    },
    {
        firstName: 'Lukas',
        id: '514',
        lastName: 'Ertl',
    },
    {
        firstName: 'David',
        id: '569',
        lastName: 'Andraschko',
    },
    {
        firstName: 'Sarah',
        id: '590',
        lastName: 'Himmer',
    },
    {
        firstName: 'Lea',
        id: '593',
        lastName: 'Eichhorn',
    },
    {
        firstName: 'Andre',
        id: '599',
        lastName: 'Eitenberger',
    },
];

export const JOB_STATUS_INFO: Record<
    JobAdStatus,
    { color: string; label: string }
> = {
    ACTIVE: { color: 'green', label: 'Aktiv' },
    CANCELLED: { color: 'red', label: 'Storniert' },
    DELETED: { color: 'red', label: 'Archiviert' },
    'DEMO-JOB': { color: 'blue', label: 'Demo-Job' },
    DRAFT: { color: 'blue', label: 'Entwurf' },
    EXPIRED: { color: 'gray', label: 'Abgelaufen' },
    'IN-REVIEW': { color: 'gray', label: 'In Prüfung' },
    INACTIVE: { color: 'gray', label: 'Deaktiviert' },
    LOCKED: { color: 'red', label: 'Gesperrt' },
    REQUESTED: { color: 'gray', label: 'Angefragt' },
    SCHEDULED: { color: 'green', label: 'Geplant' },
};

export const JOB_STATUS_EXCLUDED = ['IN-REVIEW', 'REQUESTED', 'DEMO-JOB'];

export const JOB_STATUS_OPTIONS = Object.entries(JOB_STATUS_INFO)
    .filter(([key]) => !JOB_STATUS_EXCLUDED.includes(key as JobAdStatus))
    .map(([key, value]) => ({
        label: value.label,
        value: key.toLowerCase(),
    }));

export const JobAdStatuses = [
    'ACTIVE',
    'CANCELLED',
    'DELETED',
    'DEMO-JOB',
    'DRAFT',
    'EXPIRED',
    'IN-REVIEW',
    'INACTIVE',
    'LOCKED',
    'REQUESTED',
    'SCHEDULED',
] as const;

export const JOB_AD_LIST_ICON_STYLE_OPTIONS: JamFormStepDesignListIconStyleOption[] =
    [
        {
            icon: 'bullet-point',
            key: 'FILLED-CIRCLE',
            style: 'disc',
        },
        {
            icon: 'bullet-square',
            key: 'RECTANGLE',
            style: 'square',
        },
        {
            icon: 'bullet-triangle',
            key: 'ARROW',
            style: 'disclosure-closed',
        },
        {
            icon: 'bullet-circle',
            key: 'BORDER-CIRCLE',
            style: 'circle',
        },
    ];

export const JOB_AD_PREVIEW_MODE_OPTIONS = [
    {
        icon: 'desktop',
        iconOnly: true,
        label: 'Desktop',
        value: 'desktop',
    },
    {
        icon: 'tablet',
        iconOnly: true,
        label: 'Tablet',
        value: 'tablet',
    },
    {
        icon: 'phone',
        iconOnly: true,
        label: 'Mobile',
        value: 'mobile',
    },
];

export const JOB_LIST_MODAL_CONTENT: {
    [key in JobListInternalConfirmationModalType]: {
        action?: string;
        text?: string;
        title?: string;
        disabled?: boolean;
    };
} = {
    deactivate: {
        action: 'Deaktivieren',
        title: 'Willst du den Job wirklich deaktivieren?',
    },
    freeProlongation: {
        action: 'Kulanz-Verlängerung (14 Tage)',
        title: 'Willst du den Job wirklich um 14 Tage verlängern?',
    },
    lock: {
        action: 'Sperren',
        title: 'Willst du den Job wirklich sperren?',
    },
    none: {},
    reactivate: {
        action: 'Reaktivieren',
        title: 'Willst du den Job wirklich reaktivieren?',
    },
    remind: {
        action: 'Empfänger speichern',
        title: 'An wen soll eine Erinnerungs-E-Mail gesendet werden?',
    },
    unlock: {
        action: 'Entsperren',
        title: 'Willst du den Job wirklich entsperren?',
    },
};

export const JOB_LIST_MODAL_MESSAGES: Record<
    string,
    { success: string; error: string; wrongStatus: string }
> = {
    deactivate: {
        error: 'Beim Deaktivieren des Jobs ist ein Fehler aufgetreten',
        success: 'Der Job wurde erfolgreich deaktiviert',
        wrongStatus: 'Diese Aktion ist bei diesem Inserat nicht möglich.',
    },
    freeProlongation: {
        error: 'Beim Verlängern des Jobs ist ein Fehler aufgetreten.',
        success: 'Der Job wurde erfolgreich verlängert.',
        wrongStatus: 'Diese Aktion ist bei diesem Inserat nicht möglich.',
    },
    lock: {
        error: 'Beim Sperren des Jobs ist ein Fehler aufgetreten',
        success: 'Der Job wurde erfolgreich gesperrt',
        wrongStatus: 'Diese Aktion ist bei diesem Inserat nicht möglich.',
    },
    reactivate: {
        error: 'Beim Reaktivieren des Jobs ist ein Fehler aufgetreten',
        success: 'Der Job wurde erfolgreich reaktiviert',
        wrongStatus: 'Diese Aktion ist bei diesem Inserat nicht möglich.',
    },
    remind: {
        error: 'Beim Setzen des Reminders ist ein Fehler aufgetreten',
        success: 'Der Reminder wurde erfolgreich gesetzt',
        wrongStatus: 'Diese Aktion ist bei diesem Inserat nicht möglich.',
    },
    unlock: {
        error: 'Beim Entsperren des Jobs ist ein Fehler aufgetreten',
        success: 'Der Job wurde erfolgreich entsperrt',
        wrongStatus: 'Diese Aktion ist bei diesem Inserat nicht möglich.',
    },
};

export const VALIDATION_CHAR_LIMITS = {
    textGroupItemLarge: {
        limit: 1000,
    },
    textGroupItemStandard: {
        limit: 500,
    },
    textLarge: {
        limit: 10000,
    },
    textMedium: {
        limit: 1000,
    },
    textSmall: {
        limit: 250,
    },
    titleGroupItem: {
        limit: 250,
    },
    titleJob: {
        limit: 250,
    },
    titleMain: {
        limit: 400,
    },
    titleSection: {
        limit: 250,
    },
    url: {
        limit: 1000,
    },
};

export const JOB_STATUS_ACTION_QA: JobActionItem[] = [
    { action: 'approve', versionStatus: 'APPROVED' },
    { action: 'skip', versionStatus: 'SKIPPED' },
    { action: 'reject', versionStatus: 'REJECTED' },
];
